// extracted by mini-css-extract-plugin
export var Aboutmain = "styles-module--Aboutmain--sg3vE";
export var aboutContainer = "styles-module--aboutContainer--LV8aW";
export var aboutParagraph = "styles-module--aboutParagraph--3iyIu";
export var companyCls = "styles-module--companyCls--hlMU2";
export var detail2 = "styles-module--detail2--Rxe7f";
export var graph = "styles-module--graph--xRMpU";
export var graphh = "styles-module--graphh--cKuMM";
export var heading1 = "styles-module--heading1--Cx1Fw";
export var primary = "\"abc\"";
export var secondary = "\"dec\"";