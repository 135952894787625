import React from "react"
import "./styles.scss"
import { Col, Container, Row } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"

const CompanyJourney = () => {
	return (
		<>
			<div>
				<Container>
					<div className="mb-5">
						<p className="start">Start Your Success Journey With Us</p>
						<p className="journeyPara">
							Our team of experienced engineers helps SME businesses and funded startups reach their ambitious goals.
						</p>
					</div>
					<Row className="mt-5 pt-5 mb-5 ">
						<Col className="setOrder" md={6} xs={12}>
							<p className="headings">HQed in the Pakistan</p>
							<p className="journeyGraph">
								Our head office is based in Rahim Yar Khan, Pakistan — one of the most prominent tech hubs. This
								geographical proximity makes our team closer to clients, so you can always reach out to us in any
								convenient way.
							</p>
						</Col>
						<Col className="setOrderPic" md={6} xs={12} style={{ textAlign: "center" }}>
							<StaticImage src="../../../assets/svgs/HQed-in-ryk.svg" alt="Website IT Company" />
						</Col>
					</Row>
					<Row className="mt-5 pt-5 mb-5">
						<Col className="setOrderPic" md={6} xs={12} style={{ textAlign: "center" }}>
							<StaticImage src="../../../assets/svgs/Extensive-Expertise.svg" alt="Website Design Company" />
						</Col>
						<Col className="setOrder" md={6} xs={12}>
							<p className="headings">Extensive Expertise</p>
							<p className="journeyGraph">
								With 50+ highly qualified tech specialists on board, we are pretty aware of the features to make a
								successful product. Our development team supports you during all the stages and helps to launch your app
								or software on the market.
							</p>
						</Col>
					</Row>
					<Row className="mt-5 pt-5 mb-5 ">
						<Col className="setOrder" md={6} xs={12}>
							<p className="headings">Fast Delivery</p>
							<p className="journeyGraph">
								Our figures show that we deliver products by 30-50% faster compared to other vendors. We always strive
								to shorten your Time to Market, which is a significant competitive advantage in the modern digital
								landscape.
							</p>
						</Col>
						<Col className="setOrderPic" md={6} xs={12} style={{ textAlign: "center" }}>
							<StaticImage src="../../../assets/svgs/Fast-Delivery.svg" alt="Website Development Company" />
						</Col>
					</Row>
				</Container>
				<Container>
					<div className="mb-5">
						<p className="Values pt-5">Our Values</p>
					</div>
					<Row className="mt-5 mb-5 ">
						<Col className="setOrder" md={6} xs={12}>
							<Row>
								<Col md={2} xs={12} style={{ justifyContent: "center", display: "flex", alignItems: "center" }}>
									<StaticImage src="../../../assets/svgs/communicationn.svg" alt="Website IT Company" />
								</Col>
								<Col md={9} xs={12}>
									<p className="journeyGraph">
										Transparent and trustworthy communication lies in the core of our values and serves as a cornerstone
										of our collaboration.
									</p>
								</Col>
							</Row>
						</Col>
						<Col className="setOrder" md={6} xs={12}>
							<Row>
								<Col md={2} xs={12} style={{ justifyContent: "center", display: "flex", alignItems: "center" }}>
									<StaticImage src="../../../assets/svgs/values-c.svg" alt="Website Development Company" />
								</Col>
								<Col md={9} xs={12}>
									<p className="journeyGraph">
										We share the same values with our clients and speak the same language, which means we are always on
										the same page with you.
									</p>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row className="mt-5 pt-5 mb-5 ">
						<Col className="setOrder" md={6} xs={12}>
							<Row>
								<Col md={2} xs={12} style={{ justifyContent: "center", display: "flex", alignItems: "center" }}>
									<StaticImage src="../../../assets/svgs/support-c.svg" alt="Website IT Company" />
								</Col>
								<Col md={9} xs={12}>
									<p className="journeyGraph">
										Our support team works 24/7. No matter what part of the world you’re based in, we are always here to
										respond to your request.
									</p>
								</Col>
							</Row>
						</Col>
						<Col className="setOrder" md={6} xs={12}>
							<Row>
								<Col md={2} xs={12} style={{ justifyContent: "center", display: "flex", alignItems: "center" }}>
									<StaticImage src="../../../assets/svgs/success-a.svg" alt="Website Design Company" />
								</Col>
								<Col md={9} xs={12}>
									<p className="journeyGraph">
										We are not just the company providing high-quality development services. We treat your success as
										our own and always go the extra mile to meet your needs.
									</p>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	)
}

export default CompanyJourney
