import React from "react"
import { Container } from "react-bootstrap"
import Aboutpage from "components/pages/About"
import Formpage from "components/pages/Form"
import HeaderTwo from "components/shared/HeaderTwo"
import Footer from "components/shared/Footer"
import CompanyJourney from "components/pages/CompanyStatus"
import { Helmet } from "react-helmet"

const Companypage = () => {
	return (
		<>
			<Helmet>
				<html lang="en" />
				<title>MercurySols Technologies | Company </title>
				<meta
					name="description"
					content="We are a trusted software development and web design company with a focus on web development services. Since 2016, we have been prioritizing quality and long-term client relationships, with the goal to make every customer happy. Our use of the latest technology allows us to provide high-quality software solutions at competitive prices. Contact us to experience our commitment to exceptional web development, website development, and e-commerce solutions."
				/>
			</Helmet>
			<HeaderTwo />
			<Aboutpage />
			<CompanyJourney />
			<Container>
				<Formpage />
			</Container>
			<Footer />
		</>
	)
}

export default Companypage
