import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"
import { CompanyPage } from "../../../constants/index"
import * as styles from "./styles.module.scss"
import React from "react"
const Aboutpage = () => {
	const aboutDetails = CompanyPage.aboutDetails
	return (
		<>
			<div className={`mt-5 pt-1 ${styles.Aboutmain}`}>
				<Container className={styles.aboutContainer}>
					<Row className="mt-5">
						{aboutDetails.map((aboutDetail, index) => {
							return (
								<Col key={index} md={5} className="px-md-5">
									<h1 className={`text-start ${styles.heading1}`}>{aboutDetail.heading}</h1>
									<p className={`text-start ${styles.aboutParagraph}`}>{aboutDetail.detail}</p>
									<p className={`text-start ${styles.aboutParagraph}`}>
										{" "}
										{aboutDetail.detail1} <b className={styles.detail2}>{aboutDetail.detail2}</b> {aboutDetail.detail3}
									</p>
									<p className={`text-start ${styles.aboutParagraph}`}>{aboutDetail.detail4}</p>
								</Col>
							)
						})}
						<Col md={7} className={`pt-5 mt-5 ${styles.companyCls}`}>
							<StaticImage
								src="../../../assets/images/aboutnew.png"
								placeholder="none"
								alt="Website Development Company"
							/>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	)
}
export default Aboutpage
